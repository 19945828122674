/**
 * Global styles which apply throughout the Well Online PWA.
 *
 * Only styling which is truly global (i.e. affecting an HTML element wherever it is used throughout the app)
 * should be placed within this file. Styling which just affects a particular page area or component should be
 * placed within a specific stylesheet for the relevant React component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

.ion-color-text-alternative {
    --ion-color-base: var(--ion-color-text-alternative);
    --ion-color-base-rgb: var(--ion-color-text-alternative-rgb);
    --ion-color-contrast: var(--ion-color-text-alternative-contrast);
    --ion-color-contrast-rgb: var(--ion-color-text-alternative-contrast-rgb);
    --ion-color-shade: var(--ion-color-text-alternative-shade);
    --ion-color-tint: var(--ion-color-text-alternative-tint);
}

:root {
    color: var(--ion-color-secondary);
}

ion-card-title,
ion-card-content {
    --color: var(--ion-color-secondary);
    color: var(--ion-color-secondary);
}

h1,
h2 {
    font-weight: bold;
    color: var(--ion-color-primary);
}

h1 {
    font-size: 30pt;
}

h2 {
    font-size: 20pt;
}

h3 {
    font-size: 16pt;
}

ion-button {
    --color: var(--ion-color-text-alternative);
}

.link-button {
    color: var(--ion-color-primary);
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 1em;
    outline: none;
}

.bg-light {
    background-color: var(--ion-color-light);
    position: relative;
    z-index: 2;
}

.bg-dark {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-text-alternative);
    position: relative;
    z-index: 2;
}

.bg-primary {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-text-alternative);
}

.bg-dark h1,
.bg-dark h2,
.bg-dark a,
.bg-dark .link-button,
.bg-primary h1,
.bg-primary h2,
.bg-primary a,
.bg-primary .link-button {
    color: var(--ion-color-text-alternative);
}

.bg-dark a:hover,
.bg-primary a:hover,
.bg-primary .link-button:hover {
    color: var(--ion-color-medium);
}

.error-message {
    color: var(--ion-color-danger);
    font-weight: bold;
}

ion-item.external {
    cursor: pointer;
}

#banner-overlay-gradient stop.gradient-start {
    stop-color: var(--ion-color-primary);
}

#banner-overlay-gradient stop.gradient-end {
    stop-color: var(--ion-color-tertiary);
}

.content-container {
    position: relative;
}

.page-content {
    position: relative;
    z-index: 100;
}

.page-content img.alignnone {
    display: block;
    margin-bottom: 1em;
    height: auto;
}

.live-chat-link:hover {
    cursor: pointer;
    text-decoration: underline;
}