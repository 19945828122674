.site-searchbar {
    padding: 0 16px 16px;
    max-height: 80px;
    transition: 1s;
}

.site-searchbar ion-grid {
    --ion-grid-width-lg: 960px;
    --ion-grid-width-xl: 960px;
}

@media all and (max-width: 767px) {
    .site-searchbar.hide-searchbar {
        max-height: 1px;
        padding: 0;
    }
    
    .site-searchbar.hide-searchbar ion-searchbar.ios {
       display: none;
    }
}