.top-banner {
    background-color: var(--ion-color-primary-shade);
    padding: 10px 0;
    width: 100%;
}

.top-banner.has-link {
    cursor: pointer;
}

.top-banner-img-container {
    height: 200px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    overflow: hidden;
    position: relative;
}

.top-banner-img {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
}

.top-banner-overlay-bg,
.top-banner-overlay-text {
    display: none;
}

.top-banner-mobile-text {
    text-align: center;
    font-weight: bold;
    color: var(--ion-color-text-alternative);
    font-size: 28px;
    padding: 30px 0;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
}

@media all and (min-width: 768px) {
    .top-banner {
        background-color: transparent;
        padding: 0;
    }

    .top-banner-img-container {
        height: 400px;
    }

    .top-banner-overlay {
        width: 470px;
        height: 365px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        z-index: 100;
    }

    .top-banner-overlay-bg {
        display: block;
        z-index: 100;
        position: absolute;
    }

    .top-banner-overlay-bg .banner-overlay-1 {
        fill: var(--ion-background-color);
        opacity: 0.33;
    }

    .top-banner-overlay-bg .banner-overlay-2 {
        fill: var(--ion-color-primary);
        opacity: 0.56;
    }

    .top-banner-overlay-bg .banner-overlay-3 {
        fill: var(--ion-color-primary);
        opacity: 0.3;
    }

    .top-banner-overlay-bg .banner-overlay-4 {
        fill: url(#banner-overlay-gradient);
    }

    /* Work around potential issue with displaying gradient on iOS devices */
    .top-banner-overlay-bg .banner-overlay-4.no-gradient {
        fill: var(--ion-color-primary);
    }

    .top-banner-overlay-text {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        color: var(--ion-color-text-alternative);
        z-index: 200;
        font-weight: bold;
        padding: 0px 125px;
        font-size: 28px;
        line-height: 35px;
        margin: 20px 0 10px;
    }

    .top-banner-mobile-text {
        display: none;
    }
}