.top-content .pad-figure {
    padding: 0 10px 0;
}

@media all and (min-width: 576px) {
    .top-content .pad-figure {
        padding: 0 20px 0;
    }
}

@media all and (min-width: 768px) {
    .top-content .pad-figure {
        padding: 0 30px 0 0;
    }

    .top-content .pad-mobile {
        display: none;
    }
}