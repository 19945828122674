ion-card.topic-listing-icon-card {
    height: calc(100% - 20px);
    --ion-padding: 32px;
    --background: var(--ion-color-light);
}

ion-card.topic-listing-icon-card.ios {
    height: calc(100% - 48px);
}

ion-card.topic-listing-icon-card ion-icon {
    font-size: 64px;
}