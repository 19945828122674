/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** Adjust default Ionic app colours **/
    
    --ion-color-primary: #7FBC03;
    --ion-color-primary-rgb: 127,188,3;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #70a503;
    --ion-color-primary-tint: #8cc31c;
    
    --ion-color-secondary: #707070;
    --ion-color-secondary-rgb: 112,112,112;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #636363;
    --ion-color-secondary-tint: #7e7e7e;
    
    --ion-color-tertiary: #3F8B00;
    --ion-color-tertiary-rgb: 63,139,0;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #377a00;
    --ion-color-tertiary-tint: #52971a;
    
    --ion-color-success: #7FBC03;
    --ion-color-success-rgb: 127,188,3;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #70a503;
    --ion-color-success-tint: #8cc31c;
    
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    
    --ion-color-danger: #FF2C50;
    --ion-color-danger-rgb: 255,44,80;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e02746;
    --ion-color-danger-tint: #ff4162;
    
    --ion-color-dark: #707070;
    --ion-color-dark-rgb: 112,112,112;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #636363;
    --ion-color-dark-tint: #7e7e7e;
    
    --ion-color-medium: #EDEDED;
    --ion-color-medium-rgb: 237,237,237;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #d1d1d1;
    --ion-color-medium-tint: #efefef;
    
    --ion-color-light: #F5F5F5;
    --ion-color-light-rgb: 245,245,245;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d8d8d8;
    --ion-color-light-tint: #f6f6f6;
    
    --ion-text-color: var(--ion-color-secondary);
    --ion-text-color-rgb: var(--ion-color-secondary-rgb);
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
    
    /** Additional colours specific to the Well Online PWA: **/
    
    /* Colour to display text on highlighted/dark backgrounds */
    --ion-color-text-alternative: var(--ion-color-light);
    --ion-color-text-alternative-rgb: var(--ion-color-light-rgb);
    --ion-color-text-alternative-contrast: var(--ion-color-light-contrast);
    --ion-color-text-alternative-contrast-rgb: var(--ion-color-light-contrast-rgb);
    --ion-color-text-alternative-shade: var(--ion-color-light-shade);
    --ion-color-text-alternative-tint: var(--ion-color-light-tint);
    
    /** Other customised CSS variables **/
    --ion-font-family: "Source Sans Pro", "Helvetica Neue", "Roboto", sans-serif;
    --ion-grid-width-xs: 100%;
    --ion-grid-width-sm: 100%;
    --ion-grid-width-md: 100%;
    --ion-grid-width-lg: 100%;
}