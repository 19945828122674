.background-shape {
    position: absolute;
    bottom: 0;
    overflow: hidden;
}

.background-shape.left {
    top: -500px;
    left: -2350px;
}

.background-shape.left.content-top {
    top: -600px;
    left: -2200px;
}

.background-shape.right {
    top: -100px;
    right: -2100px;
}

.background-shape.right.content-top {
    top: -150px;
}

.background-shape .background-shape-path {
    fill: var(--ion-color-primary);
    opacity: 0.1;
}

@media all and (max-width: 767px) {
    .background-shape.mobile-hide {
        display: none;
    }

    .background-shape.left {
        top: 400px;
    }

    .background-shape.left.content-top {
        top: -400px;
    }

    .background-shape.right {
        top: 0px;
    }

    .background-shape.content-top {
        top: -150px;
    }
}

@media all and (min-width: 768px) {
    .background-shape.desktop-hide {
        display: none;
    }
}