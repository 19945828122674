#site-breadcrumbs {
    position: relative;
    z-index: 2;
}

#site-breadcrumbs,
#site-breadcrumbs a {
    color: var(--ion-color-secondary);
}

#site-breadcrumbs a {
    text-decoration: none;
}

#site-breadcrumbs a:hover {
    text-decoration: underline;
}