.btn-helpsheet-download {
    --padding-start: 3.3em;
    --padding-end: 3.3em;
}

@media all and (max-width: 575px) {
    .btn-helpsheet-download {
        --padding-start: 1.5em;
        --padding-end:   1.5em;
    }
}