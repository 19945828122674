#site-menu-horizontal {
    display: none;
}

@media all and (min-width: 768px) {
    #site-menu-horizontal {
        display: block;
        padding-bottom: 10px;
        --ion-grid-width-lg: 960px;
        --ion-grid-width-xl: 960px;
    }
    
    #site-menu-horizontal ul {
        margin: 0;
        padding: 0;
    }
    
    #site-menu-horizontal li {
        display: inline-block;
        padding: 0 15px 5px;
    }
    
    #site-menu-horizontal li a {
        display: inline-block;
        color: var(--ion-color-secondary);
        border-bottom: 2px solid var(--ion-background-color);
        text-decoration: none;
        padding-bottom: 10px;
    }
    
    #site-menu-horizontal li a.selected {
        font-weight: bold;
    }
    
    #site-menu-horizontal li a.selected,
    #site-menu-horizontal li a:hover {
        border-bottom: 2px solid var(--ion-color-primary);
    }
    
    #site-menu-horizontal li a:hover {
        transition: 1s;
    }
}

@media all and (min-width: 960px) {
    #site-menu-horizontal li {
        padding: 0 30px 5px;
    }
}