ion-card.topic-card {
    height: calc(100% - 20px);
    display: flex;
    flex-flow: column;
}
ion-card.topic-card.ios {
    height: calc(100% - 48px);
}
ion-card.topic-card img {
    width: 100%;
}

ion-card.topic-card ion-card-title.md {
    font-weight: bold;
}

ion-card.topic-card ion-card-header {
    flex: 0 1 auto;
}

ion-card.topic-card ion-card-content {
    flex: 1 1 auto;
    margin-bottom: 56px;
}

ion-card.topic-card.ios ion-card-content {
    margin-bottom: 70px;
}

ion-card.topic-card ion-card-content.card-content-md {
    padding-bottom: 16px;
}

ion-card.topic-card ion-button {
    position: absolute;
    bottom: 16px;
    transform: translateX(-50%);
    left: 50%;
}

ion-card.topic-card.ios ion-button {
    bottom: 20px;
}