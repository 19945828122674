#site-header {
    background-color: var(--ion-background-color);
}

#site-header-btns-desktop {
    display: none;
}

@media all and (min-width: 768px) { /* 576px? */
    #site-header ion-buttons {
        display: none;
    }
    
    #site-header-btns-desktop {
        display: block;
        float: right;
    }
}