.home-contact-details p ion-icon {
    padding: 2px 10px 2px 0;
    vertical-align: middle;
}

.home-contact-details p a {
    color: var(--ion-color-secondary);
    font-weight: bold;
    text-decoration: none;
}

.home-contact-details p a:hover {
    text-decoration: underline;
}

.home-contact-details ion-img {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 3px 3px var(--ion-color-medium-shade);
}

@media all and (min-width: 768px) {
    .home-contact-details .text-content {
        padding: 0 30px;
    }
}

@media all and (min-width: 960px) {
    .home-contact-details .text-content {
        padding: 0 70px;
    }
}