ion-grid.quote {
    --ion-grid-width-md: 720px;
    --ion-grid-width-lg: 720px;
    --ion-grid-width-xl: 720px;
    --ion-grid-padding: 20px;
    font-size: 20px;
    text-align: center;
}

ion-grid.quote em {
    color: var(--ion-color-dark);
    font-size: 16px;
}

ion-grid.quote .quote-img {
    border-radius: 50%;
    overflow: hidden;
}

@media all and (max-width: 575px) {
    ion-grid.quote .quote-img {
        margin: 0 auto;
        width: 50%;
    }
}