.home-bottom-content ion-img {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 3px 3px var(--ion-color-medium-shade);
}

@media all and (min-width: 768px) {
    .home-bottom-content .text-content {
        padding: 0 30px;
    }
}

@media all and (min-width: 960px) {
    .home-bottom-content .text-content {
        padding: 0 70px;
    }
}