#footer .bg-dark ion-grid {
    --ion-grid-width-lg: 960px;
    --ion-grid-width-xl: 960px;
    --ion-grid-padding: 20px;
}
#footer .bg-primary ion-grid {
    --ion-grid-padding-xs: 20px;
    --ion-grid-padding: 10px;
}

#footer h2 {
    margin-top: 0;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}

#footer ul {
    list-style-type: none;
    padding: 0;
}

#footer ul li {
    padding: 5px 0;
}

#footer ul li a,
#footer .bg-dark p a {
    text-decoration: none;
    display: block;
}

#footer ul li a:hover,
#footer .bg-dark p a:hover {
    text-decoration: underline;
}

#footer .bg-dark p {
    margin: 2em 0;
    font-size: 20px;
}

#footer .bg-dark p a {
    display: inline;
}

#footer .bg-dark p ion-icon {
    padding-right: 10px;
}

#footer #cic-footer-logo {
    vertical-align: middle;
    margin-right: 10px;
}

@media all and (max-width: 575px) {
    #footer #cic-footer-logo {
        display: block;
        margin: 10px auto;
    }
}

@media all and (max-width: 991px) {
    #footer .bg-primary ion-grid {
        padding-bottom: 60px;
    }
}