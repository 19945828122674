.live-chat-overlay-button,
.live-chat-overlay-button:before {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-transition: -webkit-box-shadow .2s ease-in-out;
    transition: -webkit-box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out,-webkit-box-shadow .2s ease-in-out;
}

.live-chat-overlay-button {
     background-color: var(--ion-color-primary);
     background-position: 50%;
     background-repeat: no-repeat;
     position: fixed;
     right: 25px;
     bottom: 25px;
     -webkit-box-shadow: none;
     box-shadow: none;
     cursor: pointer;
     z-index: 99;
}

.live-chat-overlay-button:hover,
.live-chat-overlay-button:active {
    -webkit-box-shadow: 0 0 27px 1.5px rgba(0,0,0,.2);
    box-shadow: 0 0 27px 1.5px rgba(0,0,0,.2);
    background-color: var(--ion-color-primary-shade);
}

.live-chat-overlay-button:active {
    background-color: var(--ion-color-primary-tint);
}

.live-chat-overlay-button .live-chat-overlay-button-icon {
    width: 60px;
    height: 60px;
    -webkit-transition: opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out,-webkit-transform .1s ease-in-out;
    position: fixed;
    right: 25px;
    bottom: 25px;
}

.live-chat-overlay-button .live-chat-overlay-button-icon:not(:root) {
    overflow: hidden;
}